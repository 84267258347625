
$('.menu-tap-close').click(function(){
    $('.menu-tap-close').removeClass('shown');
    $('.left-menu, .right-menu, .menu-tap-close, .submenu').removeClass('allow-scroll');
    $('.left-menu, .right-menu, .submenu').hide();
    return false;
});

$('.open-left-menu').click(function(){
    $('.menu-tap-close').addClass('shown');
    $('.l-mobile__sidebar--left').addClass('show');
    $('.left-menu').show(0);
    $('.left-menu, .menu-tap-close').addClass('allow-scroll');
    return false;
});

$('.open-right-menu').click(function(){
    $('.menu-tap-close').addClass('shown');
    $('.right-menu').show(0);
    $('.right-menu, .menu-tap-close').addClass('allow-scroll');
    return false;
});

$('.slot')
    .mouseover(function() {
        $('.sub-menu.slot').show();
    }).mouseout(function() {
    $('.sub-menu.slot').hide();
});

$('.live-casino')
    .mouseover(function() {
        $('.sub-menu.live-casino').show();
    }).mouseout(function() {
    $('.sub-menu.live-casino').hide();
});

$('.toto')
    .mouseover(function() {
        $('.sub-menu.toto').show();
    }).mouseout(function() {
    $('.sub-menu.toto').hide();
});

$('.sport-betting')
    .mouseover(function() {
        $('.sub-menu.sport-betting').show();
    }).mouseout(function() {
    $('.sub-menu.sport-betting').hide();
});

$('.mini-game')
    .mouseover(function() {
        $('.sub-menu.mini-game').show();
    }).mouseout(function() {
    $('.sub-menu.mini-game').hide();
});

$('.submenu-reward')
    .mouseover(function() {
        $('.sub-menu.submenu-reward').show();
    }).mouseout(function() {
    $('.sub-menu.submenu-reward').hide();
});

$(".close-icon").click(function(){
    $(this).parent().remove();
});
